<template>
   <v-container id="regulamin" tag="section">
      <base-subheading class="text-center-font text-center">REGULAMIN SPRZEDAŻY PUBLIKACJI ELEKTRONICZNYCH</base-subheading>
      <div class="container" >
         <div class="d-flex" data-aos="fade-right">
            <div>
               <p>Sprzedaż książek na stronie <a href="https://www.natashalucas.pl/">www.natashalucas.pl</a> prowadzona jest przez:</br>
                  Barbara Łukaszewska</br>
                  ul. Połaniecka 2b/9</br>
                  66-400 Gorzów Wlkp</br>
                  Kontakt ze sprzedawcą możliwy jest pod adresem e-mail: <u>natasha-lucas@wp.pl</u></br>
                  Data publikacji Regulaminu: 27.05.2021 r.
               </p>
               </br>
            </div>
            <div class="ml-auto">
               <v-img  class="ml-auto" :src="require('@/assets/regulamin.png')"></v-img>
            </div>
         </div>
         <div data-aos="fade-right" data-aos-offset="200" data-aos-easing="ease-in-sine">
         <h3 class="text-center">§ 1</br>Definicje:</h3>
         <p>
            Ilekroć w Regulaminie jest mowa o:</br>
            <strong>„Regulaminie”</strong> – należy przez to rozumieć niniejszy Regulamin;
            </br><strong>„Regulaminie Sklepu internetowego”</strong> – należy przez to rozumieć dostępny na stronie https://account.1cart.eu/terms Regulamin Sklepu internetowego prowadzonego przez  Fsi;
            FSi – należy przez to rozumieć FSi Spółka z ograniczoną odpowiedzialnością z siedzibą w Krakowie (31- 323), ul. Gdyńska 19, wpisaną do Krajowego Rejestru Sądowego – rejestru przedsiębiorców przez Sąd Rejonowy dla Krakowa-Śródmieścia w Krakowie, XI Wydział Gospodarczy Krajowego Rejestru Sądowego, pod numerem KRS: 0000219900, posiadającą NIP: 9451926796, REGON: 357238140, o kapitale zakładowym wynoszącym 50 000,00 zł, e-mail: info@fsi.pl, będącą administratorem Platformy, zawierającej w imieniu Sprzedawcy umowę z Kupującym 
            </br><strong>„Platformie”</strong> – należy przez to rozumieć oprogramowanie 1koszyk, będące własnością FSi, umożliwiające Kupującym przede wszystkim dokonywanie zakupów przez dowolny, zintegrowany z Platformą kanał sprzedaży Sprzedawcy. Więcej informacji o Platformie znajduje się pod adresem www.1koszyk.pl
            </br><strong>„Sprzedawcy”</strong> – należy przez to rozumieć podmiot niebędący Konsumentem, korzystający z Platformy 1koszyk.pl w celu sprzedaży swoich E-booków, w którego imieniu FSi, jako agent, zawiera umowy sprzedaży z Kupującymi .
            </br><strong>„Kliencie”</strong> lub zamiennie „Kupującym” – należy przez to rozumieć osobę fizyczną, prawną lub jednostkę organizacyjną, korzystającą z Platformy w celu dokonywania zakupu E-booków od Sprzedawców.
            </br><strong>„E-booku”</strong> lub zamiennie „Pliku” – należy przez to rozumieć publikację w postaci elektronicznej, przeznaczoną do sprzedaży w Sklepie, jako treść cyfrowa, do odczytania za pomocą odpowiedniego oprogramowania zainstalowanego na urządzeniu elektronicznym (np. komputerze osobistym, czytniku książek elektronicznych, tablecie, smartfonie). E-book przybiera postać pliku elektronicznego, którego format zależny jest od zawartości E-booka;
            </br><strong>„Zamówieniu”</strong> – należy przez to rozumieć zamówienie na E-booki złożone przez Kupującego w Sklepie zgodnie z niniejszym Regulaminem. Dokonywanie Zamówień w Sklepie jest możliwe 24 godziny na dobę przez wszystkie dni w roku. 
            </br><strong>„Stronach”</strong> – należy przez to rozumieć łącznie Sprzedawcę i Klienta, którzy zawarli umowę na podstawie Regulaminu; pojęcie „Strony” zaś odnosi się do każdego z tych podmiotów z osobna;
            </br><strong>„Sklepie internetowym”</strong> – należy przez to rozumieć prowadzony przez Sprzedawcę pod adresem <a href="https://www.natashalucas.pl/">www.natashalucas.pl</a> sklep internetowy, za pośrednictwem którego Sprzedawca sprzedaje publikacje znajdujące się w jego ofercie;
            </br><strong>„Umowie sprzedaży”</strong> – należy przez to rozumieć umowę zawieraną poprzez Platformę pomiędzy Kupującym a Sprzedawcą, w imieniu którego działa FSi.
            </br><strong>„Koszyku”</strong> – należy przez to rozumieć składnik Sklepu internetowego, który służy do określenia przez Klienta danych dotyczących zamówienia, które tworzy w oparciu o narzędzia informatyczne oferowane przez system teleinformatyczny Sprzedawcy;
            </br><strong>„Konsumencie”</strong> – należy przez to rozumieć osobę fizyczną, o której mowa w art. 221 kc;
            </br><strong>„kc”</strong> – należy przez to rozumieć ustawę z dnia 23 kwietnia 1964 r. Kodeks cywilny;
            </br><strong>„Prawie autorskim”</strong> – należy przez to rozumieć ustawę z dnia 4 lutego 1994. o prawie autorskim i prawach pokrewnych;
            </br><strong>„Urządzenie Elektroniczne”</strong> – należy przez to rozumieć urządzenie przeznaczone do pobierania i odczytu Plików (np. komputer osobisty, tablet, smartphone, e-czytnik), pozwalające na obsługę formatu pdf;
            </br><strong>„PDF”</strong>– (ang. portable document format) format pliku przystosowany do odczytu na urządzeniach np. komputer, tablet, smartphone wyposażonych w oprogramowanie do odczytu np. Adobe Reader.
         </p>
         </br>
         </div>
         <div data-aos="fade-left" data-aos-offset="200" data-aos-easing="ease-in-sine">
         <h3 class="text-center">§ 2 </br>Postanowienia ogólne:</h3>
         <p>
            1. Regulamin korzystania ze sklepu internetowego („Sklep”) , znajdującego się pod adresem <a href="https://www.natashalucas.pl/">www.natashalucas.pl</a>, określa zasady korzystania ze Sklepu, w tym zawierania umów kupna-sprzedaży.
            </br>2. Regulamin jest dostępny na stronie <a href="https://www.natashalucas.pl/regulamin">www.natashalucas.pl/regulamin</a> dla każdego użytkownika Internetu, w tym dla każdego Kupującego.
            </br>3. Kupujący, składając zamówienie za pośrednictwem Sklepu internetowego na zakup E-booka oświadcza tym samym, że zapoznał się z treścią Regulaminu oraz Regulaminu Sklepu internetowego i akceptuje oba te regulaminy bez zastrzeżeń.
            </br>3. W zakresie nieuregulowanym Regulaminem stosuje się odpowiednio Regulamin Sklepu internetowego.
            </br>4. Sklep nie prowadzi sprzedaży egzemplarzy książek w formie papierowej.
            </br>5. Zakup E-booka możliwy jest na następujących zasadach:
            </br>{{indentTwo}}5.1. Klient, nabywając Plik, uzyskuje dostęp do E-booka lub innego produktu cyfrowego, pozwalający na odczyt poprzez Internet lub na ściągnięcie go na odpowiednie Urządzenie Elektroniczne oraz uzyskuje licencję, dającą prawo do korzystania z Plików na warunkach określonych w Regulaminie;
            </br>{{indentTwo}}5.2. Licencja, o której mowa w punkcie 5.1, jest licencją niewyłączną, udzieloną na czas nieoznaczony i nieograniczoną terytorialnie, oraz obejmuje następujące pola eksploatacji:
            </br>{{indentThree}}5.2.1. utrwalanie,
            </br>{{indentThree}}5.2.2. przechowywanie przez wprowadzanie do pamięci Urządzeń Elektronicznych,
            </br>{{indentThree}}5.2.3. odtwarzanie, z zastrzeżeniem wykorzystywania wyłącznie do własnego użytku osobistego Klienta, obejmującego również korzystanie z Pliku przez krąg osób pozostających w związku osobistym z Klientem.
            </br>{{indentTwo}}5.3. Klientowi nie przysługuje prawo do dalszej dystrybucji Pliku, powielania, odstępowania, udostępniania, odsprzedaży, publikowania i innych form korzystania nieprzewidzianych Regulaminem;
            </br>{{indentTwo}}5.4. W trakcie składania zamówienia na Plik, Klient przechodzi przez kolejne etapy:
            </br>{{indentThree}}5.4.1. wybiera zamówiony Plik,
            </br>{{indentThree}}5.4.2. wskazuje adres poczty elektronicznej, na którą po dokonaniu płatności zostanie przesłany link do odczytu i zapisu E-booka na Urządzeniu Elektronicznym,
            </br>{{indentThree}}5.4.3. potwierdza domyślny sposób płatności (płatność on-line) i dostawy (link do pobrania treści przekazany w e-mailu)
         </p>
         </br>
         </div>
         <div data-aos="fade-right" data-aos-offset="200" data-aos-easing="ease-in-sine">
         <h3 class="text-center">§ 3</br>E-booki:</h3>
         <p>
            1. Bezpośrednio przed złożeniem zamówienia przez Kupującego ma on możliwość zapoznania się z głównymi cechami E-booka (opisem, tematyką, autorem), sposobem porozumiewania się ze Sprzedawcą oraz cenie.
            </br>2. E-booki udostępniane są w formie pliku komputerowego w formacie PDF;
         </p>
         </div>
         </br>
         </div>
         <div data-aos="fade-left" data-aos-offset="200" data-aos-easing="ease-in-sine">
         <h3 class="text-center">§ 4</br>Korzystanie ze Sklepu:</h3>
         <p>
            1. Do korzystania ze Sklepu nie jest wymagana rejestracja. Konieczne są:
         </br>{{indentTwo}}1.1. uprzednia akceptacja Regulaminu przez Kupującego oraz
         </br>{{indentTwo}}1.2. zobowiązanie się do przestrzegania wszystkich zawartych w nim zasad przez Kupującego.
         </br>2. Ze Sklepu może korzystać każda osoba fizyczna, mająca pełną zdolność do czynności prawnych, jednostka organizacyjna lub osoba prawna.
         </p>
         </br>
         </div>
         <div data-aos="fade-right" data-aos-offset="200" data-aos-easing="ease-in-sine">
         <h3 class="text-center">§ 5</br>Wymagania techniczne niezbędne do korzystania ze Sklepu:</h3>
         <p>
            1. Do korzystania ze Sklepu wymagane są:
         </br>{{indentTwo}}1.1. dostęp do internetu;
         </br>{{indentTwo}}1.2. poprawnie skonfigurowana przeglądarka internetowa akceptująca pliki typu cookies – Microsoft Internet Explorer w wersji nie niższej niż 7.0 lub Mozilla Firefox w wersji nie niższej niż 2.0;
         </br>{{indentTwo}}1.3. włączona obsługa cookies oraz JavaScript;
         </br>{{indentTwo}}1.4. czynne i poprawnie skonfigurowane konto poczty elektronicznej.
         </br>2. Warunkiem korzystania z E-booków jest:
         </br>{{indentTwo}}2.1. posiadanie programu obsługującego takie pliki (np. Adobe Reader w wersji nie niższej niż 5.0
         </br>3. Sprzedawca nie ponosi odpowiedzialności za zdarzenia wynikające z niedostosowania się Kupującego do wskazanych wyżej wymogów technicznych niezbędnych do współpracy z systemem teleinformatycznym, którym się posługuje. W szczególności dotyczy to sytuacji, gdy Kupujący niepoprawnie skonfigurował lub nie skonfigurował konta poczty elektronicznej i wskutek tego nie dochodzą wiadomości e-mail od Sprzedawcy do Kupującego lub od Kupującego do Sprzedawcy.
         </p>
         </br>
         </div>
         <div data-aos="fade-left" data-aos-offset="200" data-aos-easing="ease-in-sine">
         <h3 class="text-center">§ 6</br>Autorskie prawa majątkowe. Ochrona treści na stronie Sklepu:</h3>
         <p>
            1. E-booki stanowią utwory w myśl ustawy z 4 lutego 1994 r. o prawie autorskim i prawach pokrewnych (Dz. U. z 1994 r. Nr 24, poz. 83) i podlegają ochronie przewidzianej w tym przepisie.
         </br>2. Autorskie prawa majątkowe do E-booków przysługują Sprzedawcy, zaś autorskie prawa osobiste – ich autorom.
         </br>3. Kupno E-booka nie daje Kupującemu żadnych autorskich praw majątkowych ani osobistych do E-booka. Wywołuje dokładnie taki sam skutek jak zakup egzemplarza zwykłej książki – Kupujący nabywa jedynie egzemplarz książki, a nie jakiekolwiek prawa autorskie do niej. Kupujący może korzystać z niego wyłącznie dla własnych potrzeb, zachować w pamięci komputera, wydrukować, a także sporządzić jedną kopię zapasową E-booka, jeśli jest to niezbędne do korzystania z E-booka, przy czym kopia ta nie może być używana równocześnie z E-bookiem.
         </br>4. W szczególności Kupującemu nie wolno:
         </br>{{indentTwo}}4.1. Zwielokrotniać E-booka lub dokonywać jego rozpowszechniania; zarówno w formie drukowanej, jak i elektronicznej.
         </br>{{indentTwo}}4.2. Udostępniać E-booka jakimkolwiek innym osobom; zarówno w formie drukowanej, jak i elektronicznej.
         </br>{{indentTwo}}4.3. Najmować i użyczać E-booka; zarówno w formie drukowanej, jak i elektronicznej.
         </br>{{indentTwo}}4.4. Ingerować w treść i zawartość E-booka.
         </br>{{indentTwo}}4.5. Usuwać zabezpieczenia i oznaczenia naniesione na E-booka.
         </br>5. Do E-booków nie stosuje się przepisów ustawy, o której mowa w ust. 1, o dozwolonym użytku, ponieważ stanowią one programy komputerowe (art. 77 prawa autorskiego).
         </br>6. W przypadku naruszenia autorskich praw majątkowych do E-booka osoba, która dopuściła się naruszenia (także Kupujący), zapłaci Sprzedawcy karę umowną w wysokości 3-krotnej wysokości ceny brutto tego E-booka za każdy zwielokrotniony, rozpowszechniony lub udostępniony, najęty lub użyczony egzemplarz E-booka. Nie wyłącza to prawa Sprzedawcy do dochodzenia odszkodowania przewyższającego wysokość kary umownej, jeśli wyrządzona mu szkoda przewyższa wysokość kary umownej.
         </p>
         </br>
         </div>
         <div data-aos="fade-right" data-aos-offset="200" data-aos-easing="ease-in-sine">
         <h3 class="text-center">§ 7</br>Zawarcie umów kupna-sprzedaży:</h3>
         <p>
            1. Treści zawarte na stronie Sklepu, w tym w szczególności prezentacje i opisy E-booków, nie stanowią oferty, a jedynie zaproszenie do składania ofert (negocjacji) przez Kupującego.
         </br>2. Sprzedawca zastrzega sobie prawo do zmiany E-booków oraz cen E-booków znajdujących się na stronie Sklepu oraz przeprowadzenia i odwoływania wszelkich rodzaju akcji promocyjnych oraz wyprzedaży.
         </p>
         </br>
         </div>
         <div data-aos="fade-left" data-aos-offset="200" data-aos-easing="ease-in-sine">
         <h3 class="text-center">§ 8</br>Złożenie zamówienia, płatność:</h3>
         <p>
            1. Kupujący, chcąc dokonać zamówienia na stronie Sklepu, wybiera określony Plik/Pliki poprzez kliknięcie na przycisk „dodaj do koszyka”, następnie w formularzu zamówienia w polu „Wpisz email” wpisuje adres swojej poczty elektronicznej.
         </br>2. Przed wysłaniem formularza zamówienia przez Kupującego pojawia się przycisk z widocznymi słowami: „realizuj zamówienie”.
         </br>3. W następnym kroku Kupujący określa sposób płatności, a następnie klika w przycisk „zatwierdź formę płatności”.
         </br>4. Następuje podsumowanie, w którym Kupującemu przedstawione są pola wyboru. Aby potwierdzić zamówienie Kupujący musi zaznaczyć pole przy frazie: „Akceptuję regulamin dla kupujących, politykę prywatności, <a href="https://ssl.dotpay.pl/files/regulamin_dotpay_sa_dokonywania_wplat_w_serwisie_dotpay.pdf">regulamin płatności DotPay S.A.</a> Oraz <a href="https://www.dotpay.pl/polityka-plikow-cookies/">politykę cookies DotPay S.A.</a>”  (po uprzednim zapoznaniu się z nimi).
         </br>5. Po kliknięciu w pole „Dokonaj zakupu” Kupujący zostaje przeniesiony na stronę logowania wybranego banku, za pomocą której dokonuje płatności.
         </br>6. Sprzedawca nie ponosi odpowiedzialności za skutki nieprawidłowego lub niezrozumiałego wypełnienia formularza przez Kupującego. Prawidłowe wypełnienie formularza oznacza, iż Kupujący powinien uzupełnić wszystkie rubryki oznaczone jako pola obowiązkowe, a dane powinny być zgodne ze stanem rzeczywistym.
         </p>
         </br>
         </div>
         <div data-aos="fade-right" data-aos-offset="200" data-aos-easing="ease-in-sine">
         <h3 class="text-center">§ 9</br>Realizacja zamówienia:</h3>
         <p>
           1. Kupujący jest informowany o zmianie statusu (etapach realizacji) zamówienia za pośrednictwem poczty elektronicznej.
         </br>2. Po zaksięgowaniu wpłaty Kupujący otrzymuje e-mail z linkiem do pobrania Pliku/Plików.
         </br>3. Kupujący klikając w przycisk „Pobieram treść cyfrową”, jest świadom, iż pobierając treść cyfrową przed upływem 14-dniowego terminu na odstąpienie od umowy, wyraża zgodę na dostarczenie tej treści przed upływem tego terminu i ma świadomość, że takie dostarczenie wiąże się z utratą przez niego prawa odstąpienia od umowy.
         </br>4. Kupujący nie ma prawa udostępniać Linku innym osobom. W przypadku naruszenia tego obowiązku Kupujący zapłaci Sprzedawcy karę umowną według zasad określonych w § 6 ust. 6.1
         </p>
         </br>
         </div>
         <div data-aos="fade-left" data-aos-offset="200" data-aos-easing="ease-in-sine">
         <h3 class="text-center">§ 10</br>Odstąpienie od umowy:</h3>
         <p>
          1. Kupujący, który zawarł umowę na odległość lub umowę poza lokalem przedsiębiorstwa, ma prawo odstąpić od niej bez podawania przyczyny w terminie 14 dni.
         </br>2.  Zgodnie z art. 38 ustawy o prawach konsumenta prawo odstąpienia od umowy zawartej poza lokalem przedsiębiorstwa lub na odległość nie przysługuje konsumentowi w odniesieniu do umów: o dostarczanie treści cyfrowych, które nie są zapisane na nośniku materialnym, jeżeli spełnianie świadczenia rozpoczęło się za wyraźną zgodą konsumenta przed upływem terminu do odstąpienia od umowy i po poinformowaniu go przez przedsiębiorcę o utracie prawa odstąpienia od umowy. <strong>Po ściągnięciu E-booka (treść cyfrowa) konsument traci prawo do odstąpienia od umowy.</strong>
         </p>
         </br>
         </div>
         <div data-aos="fade-right" data-aos-offset="200" data-aos-easing="ease-in-sine">
         <h3 class="text-center">§ 11</br>Postanowienia końcowe:</h3>
         <p>
           1. Sprzedawca zastrzega sobie prawo do zmiany ceny, bez uszczerbku dla praw nabytych przez Kupującego (w tym umów zawartych przed dokonaniem zmiany).
         </br>2. Sprzedawca zastrzega sobie prawo do zmiany treści Regulaminu. Zmiany obowiązywać będą od daty ich ogłoszenia i dotyczyć będą wyłącznie zamówień złożonych po tym czasie 
         </br>3. Niniejszy regulamin obowiązuje od dnia 27.05.2021r. 
         </p>
         </br>
         </div>   
      </div>
   </v-lazy>
   </v-container>
</template>

<script>
export default {
	data() {
		return {
			indentTwo: "\u00A0\u00A0",
			indentThree: "\u00A0\u00A0\u00A0\u00A0",
			isActive: false,
		};
	},
	name: "Rules",

	metaInfo() {
		return {
			meta: [
				{
					name: "description",
					content: "Regulamin strony i zakupu ebooków na stronie Natasha Lucas",
				},
				{ property: "og:title", content: "Regulamin | Natasha Lucas" },
				{
					property: "og:description",
					content: "Regulamin strony i zakupów e-booków na stronie Natasha Lucas",
				},
				{
					property: "og:url",
					content: "https://www.natashalucas.pl/regulamin",
				},
				{
					property: "og:image",
					content: "https://www.natashalucas.pl/img/icons/natashaLucas.png",
				},
				{ name: "robots", content: "index,follow" },
			],
		};
	},
	beforeRouteEnter(to, from, next) {
		document.title = "Natasha Lucas - Regulamin";
		next();
	},
};
</script>
<style lang="scss"  scoped>
.text-center-font {
	font: 22px Raleway;
}
@media (max-width: 979px) {
	.text-center-font {
		font-size: 12px;
	}
}
</style>
